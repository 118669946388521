var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Banner tertiary d-flex align-center",class:_vm.$vuetify.breakpoint.smAndDown ? '' : 'Banner__desktop'},[_c('v-container',{staticClass:"pa-0"},[(
        _vm.$route.name !== _vm.routes.menu &&
        _vm.$route.name !== _vm.routes.articles &&
        _vm.$route.name !== _vm.routes.clickAndCollect &&
        _vm.$route.name !== _vm.routes.badge &&
        _vm.$route.name !== _vm.routes.roomService &&
        _vm.$route.name !== _vm.routes.contact
      )?_c('div',[_c('h1',{staticClass:"px-4 px-md-0 font-weight-bold",class:_vm.$vuetify.breakpoint.smAndDown ? 'text-h1' : 'text-h1Desktop pb-4'},[_vm._v(" "+_vm._s(_vm.siteName ? "Bienvenue dans votre restaurant" : 'Bienvenue dans les restaurants Austrasie !')+" ")]),(!!_vm.siteName)?_c('h1',{staticClass:"px-4 px-md-0 font-weight-bold",class:_vm.$vuetify.breakpoint.smAndDown ? 'text-h1' : 'text-h1Desktop'},[_vm._v(" "+_vm._s(_vm.siteName)+" ! ")]):_vm._e()]):_c('h1',{staticClass:"px-4 px-md-0 font-weight-bold",class:_vm.$vuetify.breakpoint.smAndDown ? 'text-h1' : 'text-h1Desktop'},[_vm._v(" "+_vm._s(_vm.getNamePage(_vm.$route.name))+" ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }

















import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class AppleMessagePWA extends Vue {
  @Prop(String) siteName!: string;

  isIos = false;

  isStandalone = false;

  isHide = false;

  mounted(): void {
    this.isIos = /iphone|ipad|ipod/.test(window.navigator.userAgent.toLowerCase());
    this.isHide = window.localStorage.pwa_message === 'hide';
    this.isStandalone = window.matchMedia('(display-mode: standalone)').matches;
  }

  hideMessage(): void {
    window.localStorage.setItem('apple_message_pwa', 'hide');
    this.isHide = true;
  }
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$vuetify.breakpoint.smAndDown)?_c('v-container',{staticClass:"pa-0",class:!!_vm.customerId ? '' : 'fill-height'},[(!!_vm.customerId && !_vm.loading)?_c('div',{staticClass:"d-flex flex-wrap justify-space-between"},_vm._l((_vm.services
        .concat(_vm.onlineServices)
        .sort(function (a, b) { return (a.order < b.order ? -1 : 1); })),function(service,index){return _c('v-card',{key:index,class:index > 1
          ? 'Card d-flex justify-space-between justify-md-space-around align-center mx-4 mb-4 mr-md-4 rounded'
          : index === 0
          ? 'Card__highlight d-flex flex-column mx-4 mr-2 mb-4 rounded flex-grow-1'
          : 'Card__highlight d-flex flex-column mx-4 ml-2 mb-4 rounded flex-grow-1',attrs:{"align":"left","min-height":index > 1 ? 96 : 200,"elevation":"0"},on:{"click":function($event){return _vm.selectService({
          name: service.name,
          params: {slug: _vm.$route.params.slug},
        })}}},[_c('div',{staticClass:"ma-3 d-flex"},[_c('div',{staticClass:"tertiary pa-3"},[_c('v-icon',{staticClass:"flex-grow-0",attrs:{"medium":"","color":"primary"}},[_vm._v(" "+_vm._s(service.icon)+" ")])],1),(index < 2)?_c('div',{staticClass:"flex-grow-1 mx-10"}):_vm._e()]),_c('div',{staticClass:"d-flex flex-column justify-start flex-grow-1"},[_c('h2',{staticClass:"text-h2 pl-4 font-weight-medium"},[_vm._v(" "+_vm._s(service.title)+" ")]),_c('span',{staticClass:"caption font-weight-regular px-4"},[_vm._v(" "+_vm._s(service.description)+" ")])]),(index < 2)?_c('div',{class:index > 1 ? '' : 'd-flex justify-end flex-grow-1 align-end pb-2'},[_c('v-btn',{staticClass:"text-none mr-4",attrs:{"elevation":"0","rounded":"","fab":"","x-small":"","color":"white"},on:{"click":function($event){return _vm.selectService({
              name: service.name,
              params: {slug: _vm.$route.params.slug},
            })}}},[_c('v-icon',{attrs:{"large":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.svgIcons.chevronRight)+" ")])],1)],1):_vm._e()])}),1):_vm._e(),(!_vm.customerId && !_vm.loading)?_c('Portal',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOutside),expression:"onClickOutside"}],attrs:{"isOpenFromHome":false}}):_vm._e(),(_vm.loading)?_c('div',[_c('v-overlay',[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":"64","width":"5"}})],1)],1):_vm._e()],1):(!_vm.customerId && !_vm.loading)?_c('Portal'):_c('Menu')}
var staticRenderFns = []

export { render, staticRenderFns }









































import {Component, Vue} from 'vue-property-decorator';
import {Getter} from 'vuex-class';

import {nameRoutes} from '../tools';

@Component
export default class Banner extends Vue {
  @Getter('motd', {namespace: 'site'}) motd!: string | null;

  @Getter('name', {namespace: 'site'}) siteName!: string;

  routes = nameRoutes;

  getNamePage(route: string): string {
    switch (route) {
      case this.routes.menu:
        return 'Consultez le menu de la semaine';
      case this.routes.articles:
        return 'Découvrez nos dernières actualités';
      case this.routes.badge:
        return 'Accédez à votre compte badge';
      case this.routes.clickAndCollect:
        return 'Commande en ligne';
      case this.routes.roomService:
        return 'Room Service';
      case this.routes.contact:
        return 'Formulaire de Contact';
      default:
        return '';
    }
  }
}

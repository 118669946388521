import {
  mdiAccount,
  mdiAlert,
  mdiApps,
  mdiCalendarToday,
  mdiChartBar,
  mdiChevronDown,
  mdiChevronRight,
  mdiChevronUp,
  mdiClose,
  mdiHelpCircle,
  mdiHelpCircleOutline,
  mdiHome,
  mdiMagnify,
  mdiMapMarker,
  mdiNewspaper,
  mdiRoomService,
  mdiShopping,
  mdiSilverware,
  mdiStar,
  // eslint-disable-next-line import/no-extraneous-dependencies
} from '@mdi/js';

export const mdiSvg = {
  alert: mdiAlert,
  account: mdiAccount,
  apps: mdiApps,
  calendarToday: mdiCalendarToday,
  chartBar: mdiChartBar,
  chevronRight: mdiChevronRight,
  chevronDown: mdiChevronDown,
  chevronUp: mdiChevronUp,
  close: mdiClose,
  contact: mdiHelpCircle,
  helpCircleOutline: mdiHelpCircleOutline,
  home: mdiHome,
  mapMarker: mdiMapMarker,
  magnify: mdiMagnify,
  newspaper: mdiNewspaper,
  roomService: mdiRoomService,
  silverware: mdiSilverware,
  shopping: mdiShopping,
  star: mdiStar,
};





























































































































































import {Component} from 'vue-property-decorator';

import SelectorSite from '@/site/components/SelectorSite.vue';

import BaseService from './BaseService.vue';

@Component({components: {SelectorSite}})
export default class AppBar extends BaseService {}

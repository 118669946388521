

















































































import {Component} from 'vue-property-decorator';
import {Getter} from 'vuex-class';

import Menu from '@/menu/views/Menu.vue';
import SelectorSite from '@/site/components/SelectorSite.vue';

import BaseService from '../components/BaseService.vue';
import Portal from './Portal.vue';

@Component({
  components: {
    Menu,
    SelectorSite,
    Portal,
  },
})
export default class Home extends BaseService {
  @Getter('motd', {namespace: 'site'}) motd!: string | null;

  @Getter('name', {namespace: 'site'}) siteName!: string;

  @Getter('loading', {namespace: 'site'}) loading!: boolean;

  year = new Date().getFullYear();
}

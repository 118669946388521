/* eslint-disable @typescript-eslint/ban-types */
import {actions as articleActions} from '../../articles/store/actions';
import {AffluenceParams} from '../interfaces';
import SiteService from '../services/SiteService';

export const actions = {
  getSite: async ({commit}: {commit: Function}, slug: string): Promise<void> => {
    commit('site/SET_LOADING', true, {root: true});
    await SiteService.getSite(slug)
      .then((response) => {
        const {data} = response;
        commit('site/SET_CUSTOMER_ID', data.customerId, {root: true});
        commit('site/SET_SLUG', data.slug, {root: true});
        commit('site/SET_IS_DEMO', data.slug === 'site-demo', {root: true});
        commit('site/SET_NAME', data.name, {root: true});
        commit('site/SET_MOTD', data.motd || null, {root: true});
        commit(
          'site/SET_LINKS',
          {
            badgeUrl: data.badgeUrl,
            clickAndCollectUrl: data.clickAndCollectUrl,
            roomServiceUrl: data.roomServiceUrl,
          },
          {root: true}
        );
        commit('site/SET_IS_BOOKING_ACTIVATED', !!data.bookingConfig, {
          root: true,
        });
        commit('site/SET_ENABLE_CONTACT', data.enableContact || false, {
          root: true,
        });
        commit('site/SET_ENABLE_FEEDBACK', data.enableFeedback || false, {
          root: true,
        });

        commit('site/SET_ENABLE_AFFLUENCE', data.enableAffluence || false, {
          root: true,
        });
        commit('site/SET_AFFLUENCE_ZONES', data.affluenceZones || [], {
          root: true,
        });
        actions.resetAffluence({commit});
        articleActions.getArticles({commit}, data.customerId);
      })
      .catch(() => {
        commit('site/SET_ERROR', true, {root: true});
      })
      .finally(() => commit('site/SET_LOADING', false, {root: true}));
  },
  getSiteByCustomerId: async (
    {commit}: {commit: Function},
    customerId: string
  ): Promise<string> => {
    commit('site/SET_LOADING', true, {root: true});
    let slug = '';
    await SiteService.getSiteByCustomerId(customerId)
      .then((response) => {
        slug = response.data.slug;
        actions.getSite({commit}, slug);
      })
      .catch(() => {
        commit('site/SET_ERROR', true, {root: true});
      })
      .finally(() => commit('site/SET_LOADING', false, {root: true}));
    return slug;
  },
  getSiteList: async ({commit}: {commit: Function}, portal: string): Promise<void> => {
    commit('site/SET_LOADING', true, {root: true});
    await SiteService.getSites(portal)
      .then((response) => {
        commit('site/SET_SITE_LIST', response.data, {root: true});
        if (response.data.length === 0) commit('site/SET_ERROR', true, {root: true});
      })
      .catch(() => {
        commit('site/SET_ERROR', true, {root: true});
      })
      .finally(() => commit('site/SET_LOADING', false, {root: true}));
  },
  updateOpenSelector: ({commit}: {commit: Function}, isOpen: boolean): void => {
    commit('SET_IS_OPEN_SELECTOR', isOpen);
  },
  getAffluence: async (
    {commit}: {commit: Function},
    affluenceParams: AffluenceParams
  ): Promise<void> => {
    await SiteService.getAffluence(affluenceParams)
      .then((response) => {
        commit('site/SET_AFFLUENCE_RESPONSE', response.data || [], {root: true});
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => commit('site/SET_LOADING', false, {root: true}));
  },
  resetAffluence: async ({commit}: {commit: Function}): Promise<void> => {
    commit('site/SET_AFFLUENCE_RESPONSE', [], {root: true});
  },
};

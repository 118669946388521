import './registerServiceWorker';

import VComp from '@vue/composition-api';
import {
  BarController,
  BarElement,
  CategoryScale,
  Chart,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
} from 'chart.js';
import Vue from 'vue';
import Vuelidate from 'vuelidate';
import {sync} from 'vuex-router-sync';

import App from './App.vue';
import vuetify from './core/plugins/vuetify';
import router from './core/router';
import {store} from './core/store';

Chart.register(
  BarController,
  LineController,
  BarElement,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale
);
sync(store, router);

Vue.use(Vuelidate);
Vue.use(VComp);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');

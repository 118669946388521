import axios from 'axios';
import Vue from 'vue';
import VueAxios from 'vue-axios';
import Vuex from 'vuex';

import {article} from '@/articles/store';
import {feedback} from '@/feedback/store';
import {site} from '@/site/store';

Vue.use(Vuex);
Vue.use(VueAxios, axios);

// Export store
export const store = new Vuex.Store({
  modules: {
    site,

    article,
    feedback,
  },
});

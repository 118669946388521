import {RouteConfig} from 'vue-router';

import {nameRoutes} from '@/core';

import Contact from './views/Contact.vue';

export const feedbackRoutes: Array<RouteConfig> = [
  {
    path: '/:slug?/contact',
    name: nameRoutes.contact,
    component: Contact,
  },
];






import {Component, Vue} from 'vue-property-decorator';

import Iframe from '@/core/components/Iframe.vue';

@Component({components: {Iframe}})
export default class Menu extends Vue {}






























































































import {Component} from 'vue-property-decorator';

import BaseService from './BaseService.vue';

@Component
export default class BottomBar extends BaseService {
  value = this.$route.name || '';

  sheet = false;
}

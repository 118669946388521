var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$vuetify.breakpoint.smAndDown)?_c('div',[_c('v-app-bar',{attrs:{"color":"white","height":"70"}},[_c('v-container',{staticClass:"pa-0 fill-height",class:{home: _vm.$route.name === _vm.routes.home}},[_c('v-spacer'),_c('div',{staticClass:"Appbar d-flex align-center"},[_c('transition',{attrs:{"name":"app-bar"}},[_c('v-img',{directives:[{name:"show",rawName:"v-show",value:((!_vm.isOpenSelector || !_vm.customerId) && !_vm.isDemo),expression:"(!isOpenSelector || !customerId) && !isDemo"}],staticClass:"Header__logo shrink mr-2",attrs:{"contain":"","position":_vm.siteList.length > 1 ? 'left center' : 'center center',"src":require('../assets/images/logos/logo.svg'),"transition":"scale-transition","width":"100","height":"60"}})],1),(_vm.siteList.length > 1)?_c('SelectorSite',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOutside),expression:"onClickOutside"}],attrs:{"isOpenFromHome":true}}):_vm._e()],1),_c('v-spacer')],1)],1)],1):_c('v-app-bar',{attrs:{"color":"white","height":"70"}},[_c('v-container',{staticClass:"pa-0 fill-height"},[_c('div',{staticClass:"d-flex align-center flex-grow-0"},[(!_vm.isDemo)?_c('v-img',{staticClass:"Header__logo shrink mr-2",attrs:{"contain":"","src":require('../assets/images/logos/logo.svg'),"transition":"scale-transition","width":"140","height":"50"}}):_vm._e(),(_vm.customerId && _vm.siteList.length > 1)?_c('SelectorSite',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOutside),expression:"onClickOutside"}],staticClass:"SelectorSite",attrs:{"isOpenFromHome":true}}):_vm._e()],1),_c('v-spacer',{staticClass:"flex-grow-1"}),(_vm.$vuetify.breakpoint.lgOnly)?_c('div',{staticClass:"d-flex align-center pl-2 flex-grow-0"},_vm._l((_vm.services
            .concat(_vm.onlineServices)
            .sort(function (a, b) { return (a.order < b.order ? -1 : 1); })),function(service){return _c('div',{key:service.name},[(_vm.customerId)?_c('v-btn',{staticClass:"mr-2",attrs:{"exact":"","text":"","plain":"","active-class":"primary--text","ripple":false,"input-value":_vm.$route.name === service.name ||
              (service.name === _vm.routes.menu && _vm.$route.name === _vm.routes.home)},on:{"click":function($event){return _vm.selectService({
                name: service.name,
                params: {slug: _vm.$route.params.slug},
              })}}},[_c('span',[_vm._v(_vm._s(service.title || service.name))])]):_vm._e()],1)}),0):_c('div',{staticClass:"d-flex align-center pl-2 flex-grow-0"},[_vm._l((_vm.services.filter(function (service) { return service.name !== _vm.routes.contact; })),function(service){return _c('div',{key:service.name,staticClass:"d-flex align-center pl-2 flex-grow-0"},[(_vm.customerId)?_c('v-btn',{staticClass:"mr-2",attrs:{"exact":"","text":"","plain":"","active-class":"primary--text","ripple":false,"input-value":_vm.$route.name === service.name ||
              (service.name === _vm.routes.menu && _vm.$route.name === _vm.routes.home)},on:{"click":function($event){return _vm.selectService({
                name: service.name,
                params: {slug: _vm.$route.params.slug},
              })}}},[_c('span',[_vm._v(_vm._s(service.title || service.name))])]):_vm._e()],1)}),(_vm.onlineServices.length)?_c('v-menu',{staticClass:"flex-grow-0",attrs:{"bottom":"","left":"","color":"white","close-on-content-click":false,"transition":"scale-transition","offset-y":"","z-index":100},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
              var value = ref.value;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"flex-grow-0",attrs:{"text":"","plain":"","active-class":"primary--text","ripple":false,"input-value":value}},'v-btn',attrs,false),on),[_vm._v(" Services ")])]}}],null,false,1029843703)},[_c('v-list',{attrs:{"color":"white"}},[_c('v-list-item-group',{attrs:{"color":"primary"}},[_vm._l((_vm.onlineServices),function(service,i){return _c('v-list-item',{key:i,on:{"click":function($event){return _vm.selectService({
                    name: service.name,
                    params: {slug: _vm.$route.params.slug},
                  })}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(service.title)+" ")])],1)}),_c('v-list-item',{on:{"click":function($event){return _vm.selectService({
                    name: _vm.routes.contact,
                    params: {slug: _vm.$route.params.slug},
                  })}}},[_c('v-list-item-title',[_vm._v(" Contact ")])],1)],2)],1)],1):_vm._e()],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }